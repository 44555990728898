import React from 'react';
import { Link } from 'react-router-dom';

const Home = () => {
    return (
        <div className="home-container" style={{ padding: '20px', maxWidth: '800px', margin: '0 auto', fontFamily: 'Arial, sans-serif', color: '#333' }}>
            <h2>Welcome to the Sonic Reflections of Michael Ramsey Ferras</h2>
            <p>Hello, hello. I'm Michael Ramsey Ferras (MRF). I'm a British expat living in the outskirts of the Chicagoland area in a delightful little town known as Oswego. This space is dedicated to exploring the resonances of shoegaze and beyond, with a keen eye on the steady decay that colors our contemporary moment.</p>

            <h3>Featured Insight</h3>
            <p><strong>In-Depth Analysis: "I Won't Be by Your Side" by Family Reunion</strong></p>
            <p>
                This track stands as a melancholic testament to the disillusionment that permeates our modern existence. Laden with haunting melodies and lyrics, this track has come to characterize deep that erstwhile subgenre known Midwest shoegaze. I'll tip you off to my biases, young reader. I am a Family Reunion head, as they say. I fully admit it. Part of the reason for that is Rose's ability to capture a moment in time. This track in particular accurately conveys the essence of a world that seems increasingly out of tune with itself. 
                <Link to="/review/i-wont-be-by-your-side">Read my full analysis</Link> and immerse yourself in the reflective despair that defines our new epoch.
            </p>

            <h3>Personal Musings</h3>
            <p>
                As we traverse this modern landscape, one cannot help but notice the creeping signs of a society in decline. It's as if the very air we breathe is tainted — not just by the pollutants that choke our urban environments, but by a more insidious decadence that encompasses all things. The food we eat, once a source of nourishment, has become yet another vector for disaster, with waves of outbreaks reminding us that the safeguards of yesterday have been eroded by the relentless consequences of deregulation. Yet, amidst this decay, there is a strange beauty — a haunting allure that calls to those of us who can still see through the cracks in the veneer. Here, in this space, I shall document not just the music that moves me, but the unsettling truths that lurk beneath the surface.
            </p>

            <h3>Recent Entries</h3>
            <ul className="blog-link-container">
                <li><Link to="/musings/the-melancholy-of-hypermodernity">The Melancholy of Hypermodernity: Why Our Age Feels Out of Sync</Link></li>
                <li><Link to="/musings/decay-and-dissonance">Decay and Dissonance: A Reflection on Cultural Decline</Link></li>
                <li><Link to="/musings/shoegaze-and-the-end-of-romanticism">Shoegaze and the End of New Romanticism: A Sonic Eulogy</Link></li>
                <li><Link to="/musings/the-failure-of-new-sincerity">The Failure of New Sincerity and the Rise of New Irony</Link></li>
                <li><Link to="/musings/navigating-the-void">Navigating the Void: Finding Meaning in a Post-Meaning World</Link></li>
            </ul>

            <h3>Further Exploration</h3>
            <ul className="home-link-container">
                <li><Link to="/artist-info">Discover the Minds Behind the Music</Link></li>
                <li><Link to="/interview">A Conversation with Matthew Pontius Semble</Link></li>
                <li><Link to="/merch">Support the Scene: Merch and More</Link></li>
                <li><Link to="/about">About MRF: My Journey through Sound and Society</Link></li>
                <li><Link to="/contact">Get in Touch: Share Your Thoughts with MRF</Link></li>
            </ul>
        </div>
    );
};

export default Home;

import React from 'react';
import '../ErrorPage.css';

const ErrorPage = () => {
    return (
        <div className="error-body">
            <div className="error-container">
                <h2>Error 40X: Anomaly Detected</h2>
                <p>
                    The request has encountered an unforeseen condition that prevents it from being processed. The parameters of this request are not consistent with known variables.
                </p>
                <p>
                    The destination you sought appears to be misaligned. It may have shifted, or perhaps it was never here to begin with. <a href="/">Return to the origin</a>, or seek further guidance from the Custodians at <a href="mailto:support@recordsoftheobscure.net">support@recordsoftheobscure.net</a>.
                </p>
                <p>
                    <em>Note:</em> Prolonged exposure to unresolved anomalies may result in cognitive effects. Proceed with due caution.
                </p>
                <footer>
                    <p>Anomaly Reference: #40X-314159</p>
                    <p>Processed by: Blackbox Kernel v9.7</p>
                    <p>© 2024 - Records of the Obscure</p>
                </footer>
            </div>
        </div>
    );
};

export default ErrorPage;